(function($){

    $('.nscrollbar').each(function() {
        var $this = $(this);
        $this.parent().niceScroll($this, {
            autohidemode: false,
            background: '#CCCCCC',
            cursorwidth: '8px',
            cursorborder: '1px solid #CCCCCC',
        });
    });

})(jQuery);
